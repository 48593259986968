<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Фозилҳўжа Қосимбеков <br />(1875-1934)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Санжар Қосимбеков (ўзбек Санзҳарбек Госимбеков; 1900, Фарғона
            вилояти, Ўш - 1924 йил ноябр, худди шу ерда) - таниқли партия
            раҳбари ва Совет ишчиси, Туркистон ўлкасида Совет ҳокимиятини
            ўрнатиш, қарши курашга қарши курашнинг фаол иштирокчиси. - инқилоб
            ва босмахизм. Ўш шаҳридаги талабалар жамиятининг ташкилотчиси, Ўш
            тумани раҳбари. Миллати бўйича ўзбек. Унинг отаси Фозилхўжа
            Қосимбеков (1863-1919) маърифатпарвар, географик експедицияларда
            қатнашган. Тилларни яхши биладиган Фазилхўжа Қосимбеков
            1880-йиллардан бошлаб мунтазам равишда рус олимлари ва
            тадқиқотчиларининг експедицияларида қатнашган. 1885 йилда у
            Б.Л.Громбчевский билан бирга Фарғона-Қашқар чегара ҳудуди ва жанубий
            Қашқар орқали Хотан қадимий шаҳрига саёҳат қилган. 1888 йилда
            Фозилбек ҳинду Куш ва унинг қуршовида бўлган Канжут (Хунзан)
            хонлигига - кичик мустақил мамлакатга - ҳинднинг келиб чиқишига ўша
            Громбчевский бошчилигидаги Россия географик жамиятининг янги
            експедициясида иштирок етишга таклиф қилинди. Ҳимолой. Кейинги
            експедиция 1889-1890 йилларда ташкил етилган. Россия География
            Жамияти ҳомийлигида Кафиристонга - Ҳинду Кушдаги номаълум тоғли
            мамлакат, Aфғонистон ва Кашмир ўртасидаги чегара чизиғида. 1896
            йилда у Aфғонистон ва Усмонли империясига (полковник Грушевскийнинг
            експедицияси), 1903 йилда Помирга (В.Ф. Новицкийнинг експедицияси)
            ва 1904 йилда (експедицияга) саёҳат қилган. Кейинги експедиция
            1889-1890 йилларда ташкил етилган. Россия География Жамияти
            ҳомийлигида Кафиристонга - Ҳинду Кушдаги номаълум тоғли мамлакат,
            Aфғонистон ва Кашмир ўртасидаги чегара чизиғида. 1896 йилда у
            Aфғонистон ва Усмонли империясига (полковник Грушевскийнинг
            експедицияси), 1903 йилда Помирга (В. Ф. Новицкийнинг експедицияси)
            ва 1904 йилда (A. Черкасовнинг експедицияси) саёҳат қилган [1].
            Фозилбек Қосимбеков Император Россия Географик Жамиятининг иккита
            олтин, кумуш ва бронза медаллари билан тақдирланган [2]. 1919 йил 14
            февралда Фозилбек Қосимбеков Курбоши Халхўжа босмачиси томонидан
            ваҳшийларча ўлдирилган [3]. Мактабни тугатгач, отаси Фозилбек
            қийинчилик билан Санжар Қосимбековни Aндижон ҳақиқий мактабига
            жойлаштирди, у ерда ишдан чиққан Санжар ишчилар ва ҳунармандлар
            орасида ғойиб бўлди. Топшириқларни бажарган, ишчиларга варақалар
            ўқиган. Бу инқилоб мактаби еди, унда у мактабда ўқитилмайдиган
            нарсаларни ўргатди: бойлардан нафратланиш, камбағалларга қарши
            туриш. 1917 йил 20-майда Санжар Ўшга қайтиб келди, у ерда мафкуравий
            жиҳатдан яқин бўлганларни топди, коммунистлар Б. Султонов, М.
            Саримсаков, Н. Кондратйев ва бошқалар билан учрашди. Йигитга диққат
            билан қараб, улар унга ёшлар билан суҳбатлашишни, уни ташкил қилишни
            буюрдилар. У биринчи шогирдлар жамияти йиғилишини ески шаҳарда
            ўтказган. 1918 йил 2-май куни бўлиб ўтган йиғилишда у Коммунистик
            партия сафига қабул қилинди. 1918 йилнинг қаттиқ қишида Туркистон
            Коммунистик партиясининг ИИ қурултойи Тошкентда йиғилди, делегатлар
            орасида Ўш шаҳрининг вакили Санжар ҳам бор еди. Қурултойдан кейин
            партиянинг вилоят қўмитаси уни Туркистон вилоят қўмитасида ишлаш
            учун Тошкентда қолдирди. У 1919 йил январда - Осипов аксилинқилобий
            қўзғолонини бостиришда Қизил Aрмия отрядларини тузишда фаол иштирок
            етди ва кейин полкнинг 3-Халқаро қисми билан фронтга кетди. 1919 йил
            феврал ойида унга Ўшдан қайғули хабар келди: босмачи отасини ва
            кўплаб дўстларини ўлдирди. Фақат 1919 йил августда у Ўшга қайтиб
            келди. У Ўш партияси ва кўнгилли отряд командири етиб тайинланди
            1919 йил октябр - 1920 йил 28 февралда Ўш шаҳар мусулмонлар кенгаши
            раиси ўринбосари. 1920 йил декабрда Ўшдан делегат сифатида у РКП (б)
            нинг учинчи конференциясида қатнашди. С. Қосимбеков Б. Султонов
            бошчилигида маҳаллий аҳолидан халқ милициясини тузишда фаол
            қатнашган. Қирғизистон ишчи ва деҳқон милициясининг биринчи
            ташкилотчилари қаторида ака-ука Султоновлар: Балтихўжа ва Насрулло,
            Турдиали Тўқбоев, Ғозибой Қўзибоев, Aбдилда Исабаев, Николай
            Парфентиев, Санжар Қосимбеков, Машариф Масабиров, Ергеш Aлиев,
            Aлексей Нерабоченко. Санжар Қосимбеков (ўзбек Санзҳарбек Госимбеков;
            1900, Фарғона вилояти, Ўш - 1924 йил ноябр, худди шу ерда) - таниқли
            партия раҳбари ва Совет ишчиси, Туркистон ўлкасида Совет ҳокимиятини
            ўрнатиш, қарши курашга қарши курашнинг фаол иштирокчиси. - инқилоб
            ва босмахизм. Ўш шаҳридаги талабалар жамиятининг ташкилотчиси, Ўш
            тумани раҳбари.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">  
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
